'use client';

import { useCallback } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import { convertObjectToQueryParam } from 'utils/misc';
import { Location } from 'location.d';
import { ANYWHERE_LOCATION, NEAR_TO_YOU_LABEL } from 'constants/index';
import locationStore from 'stores/LocationStore';
import { statesList, urlPathname } from 'constants/search';

type Params = {
  search?: boolean;
  path?: string;
  query?: object;
  location?: Location;
  changeCity?: boolean;
};

const nonRedirectPaths = ['/local/', '/favoritos', '/home'];

export function useGetPathWithLocation() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const getPathWithLocation = useCallback(
    (params?: Params) => {
      const query = convertObjectToQueryParam({ ...searchParams, ...params?.query, city: '' });
      const currentPathSplitted = pathname?.split('/');

      if (params?.path) {
        currentPathSplitted?.splice(1, 1, params.path);
      }
      const shouldNotRedirect = pathname === '/' || nonRedirectPaths.some((item) => pathname?.startsWith(item));
      const shouldPerformRedirect = params?.search ? true : !shouldNotRedirect;
      const slugCity = params?.location?.slug || locationStore?.selectedLocation?.slug || '';
      const endSlug = currentPathSplitted?.[2]?.slice(-3) || '';
      if (shouldPerformRedirect && slugCity && !currentPathSplitted?.includes(slugCity)) {
        if (params?.changeCity || statesList.includes(endSlug)) {
          currentPathSplitted?.splice(2, 1, params?.location?.slug || locationStore.selectedLocation.slug);
        } else {
          currentPathSplitted?.splice(2, 0, params?.location?.slug || locationStore.selectedLocation.slug);
        }
      }

      if (
        (params?.location?.city === NEAR_TO_YOU_LABEL || params?.location?.city === ANYWHERE_LOCATION)
        && currentPathSplitted?.[1] === urlPathname.events
        && statesList.includes(endSlug)
      ) {
        currentPathSplitted?.splice(2, 1);
      }

      return (currentPathSplitted?.join('/') || '') + query;
    },
    [pathname, searchParams, locationStore.selectedLocation],
  );

  return getPathWithLocation;
}
