import { IconLoading } from '@sympla/sympla-components/ActionIcons';
import { LoadingContainer, LoadingFullScreenContainer } from './Loading.css';

interface LoadingProps {
  isNotFullScreen?: boolean
}

function Loading({ isNotFullScreen = false }: LoadingProps) {
  return (
    <div className={!isNotFullScreen ? LoadingFullScreenContainer : LoadingContainer}>
      <IconLoading />
    </div>
  );
}

export default Loading;
