import { getDateSearch } from 'utils/misc';

export const searchEmptyList = [
  {
    id: 'today', label: 'hoje', date: 'hoje', param: getDateSearch('hoje'),
  },
  {
    id: 'tomorrow', label: 'amanhã', date: 'amanha', param: getDateSearch('amanha'),
  },
  {
    id: 'thisWeek', label: 'nesta semana', date: 'esta-semana', param: getDateSearch('esta-semana'),
  },
  {
    id: 'thisWeekend', label: 'neste fim de semana', date: 'este-fim-de-semana', param: getDateSearch('este-fim-de-semana'),
  },
  {
    id: 'nextWeek', label: 'na próxima semana', date: 'proxima-semana', param: getDateSearch('proxima-semana'),
  },
  {
    id: 'thisMonth', label: 'neste mês', date: 'este-mes', param: getDateSearch('este-mes'),
  },
];
