/* eslint-disable no-console */
import { Location } from 'location';
import { PAGES_URL } from 'constants/index';
import { convertObjectToQueryParam } from './misc';

interface Item {
  url?: string;
  category?: string;
  date?: string;
  layoutPage?: string;
  urn: string;
  ordem?: string;
}

interface ForwardParams {
  [key: string]: string;
}

interface LinkOptions {
  [key: string]: {
    baseUrl: () => string;
    newTab: boolean;
    canRedirect: boolean;
  };
}

interface RedirectOptions {
  param: string;
  routeType: string;
  item?: Item;
  isDirectory?: boolean;
  forwardParams?: ForwardParams;
  userLocation: Location | null;
  isAllEvents?: boolean;
  pathname?: string
}

const createSeeAllLink = (
  matrixName: string,
  userLocation: Location | null,
  item?: Item,
  isDirectory?: boolean,
  pathname?: string,
): string => {
  const urlEvents = `${PAGES_URL.events}/${userLocation?.slug ? `${userLocation.slug}/` : ''}`;
  const collection = item?.urn || '';
  const ordem = item?.ordem;
  let params = '';
  if (collection) params = `c=${collection}`;
  if (ordem) params = params ? `${params}&ordem=${ordem}` : `?ordem=${ordem}`;

  const matrixOptions: Record<string, () => string> = {
    'home-matrix': () => (isDirectory
      ? `${urlEvents}${collection}`
      : `${urlEvents}c=${collection}`),
    city: () => (isDirectory
      ? `${pathname ?? urlEvents}/${collection}`
      : `${pathname ?? urlEvents}?${params}`),
    collection: () => (isDirectory
      ? `${pathname ?? PAGES_URL.events}/${collection}${ordem ? `?ordem=${ordem}` : `&ordem=${ordem}`}`
      : `${pathname ?? PAGES_URL.events}?${params}`),
  };

  return matrixOptions[matrixName]?.() || '';
};

const handleCategoryChanges = (categorySlug: string): string => {
  const getUrlPaths = window.location.pathname.split('/');
  const urlToBuild = getUrlPaths.slice(1, -1);

  if (categorySlug !== '') {
    urlToBuild.push(categorySlug);
    return `/${urlToBuild.join('/')}`;
  }
  return window.location.pathname;
};

export const createLinkToRedirect = ({
  param,
  routeType,
  item,
  isDirectory,
  userLocation,
  forwardParams,
  isAllEvents,
  pathname,
}: RedirectOptions): string => {
  if (param === '' && routeType === '') {
    console.warn('< CREATE LINK : ERROR : routeType or param is empty >');
    return '#';
  }

  const linkOptions: LinkOptions = {
    collection: {
      baseUrl: () => `${PAGES_URL.events}/${userLocation?.slug ? `${userLocation.slug}/` : ''}${param}`,
      newTab: false,
      canRedirect: false,
    },
    venue: {
      baseUrl: () => `${param}`,
      newTab: true,
      canRedirect: false,
    },
    city: {
      baseUrl: () => `${PAGES_URL.events}/${param}`,
      newTab: false,
      canRedirect: false,
    },
    recent: {
      /** TODO: REMOVE TERNARY WHEN EVENT PAGE IS READY */
      baseUrl: () => (item?.url ? String(item?.url) : `/evento/nome-evento/${param}`),
      newTab: true,
      canRedirect: false,
    },
    'event-sympla': {
      /** TODO: REMOVE TERNARY WHEN EVENT PAGE IS READY */
      baseUrl: () => {
        if (forwardParams) {
          const paramsPath = convertObjectToQueryParam(forwardParams);
          return `${item?.url}${paramsPath}`;
        }
        return item?.url ? String(item?.url) : `/evento/nome-evento/${param}`;
      },
      newTab: true,
      canRedirect: false,
    },
    'event-ttrs': {
      baseUrl: () => `${item && item.url}`,
      newTab: true,
      canRedirect: false,
    },
    organizer: {
      baseUrl: () => `/produtor/${item?.url}`,
      newTab: false,
      canRedirect: false,
    },
    'see-all-collection': {
      baseUrl: () => '/categorias',
      newTab: false,
      canRedirect: false,
    },
    'see-all-event': {
      baseUrl: () => createSeeAllLink(param, userLocation, item, isDirectory, pathname),
      newTab: false,
      canRedirect: false,
    },
    'see-all-venue': {
      baseUrl: () => '',
      newTab: false,
      canRedirect: false,
    },
    'see-all-city': {
      baseUrl: () => '/cidades',
      newTab: false,
      canRedirect: false,
    },
    'see-all-organizer': {
      baseUrl: () => '/organizadores',
      newTab: false,
      canRedirect: false,
    },
    /** category */
    'category-changes': {
      baseUrl: () => handleCategoryChanges(param),
      newTab: false,
      canRedirect: true,
    },
    /** default redirect */
    'default-redirect': {
      baseUrl: () => param,
      newTab: false,
      canRedirect: true,
    },
    'default-redirect-to-new-tab': {
      baseUrl: () => param,
      newTab: true,
      canRedirect: true,
    },
    'search-bar-default-filter': {
      baseUrl: () => `${PAGES_URL.events}${userLocation?.slug ? `/${userLocation.slug}` : ''}${item?.category ? `/${item?.category}` : ''}${
        item?.date ? `/${item?.date}` : ''
      }${isAllEvents ? '/todos-eventos' : ''}${param}`,
      newTab: true,
      canRedirect: false,
    },
  };

  const hasNewTab = linkOptions[routeType]?.newTab;
  const hasRedirect = linkOptions[routeType]?.canRedirect;
  const finalLinkToRedirect = linkOptions[routeType]?.baseUrl();

  if (finalLinkToRedirect === undefined) {
    console.warn('< CREATE LINK : ERROR : final link is not a string >', routeType, finalLinkToRedirect);
    return '#';
  }

  if (typeof window !== 'undefined' && finalLinkToRedirect && hasRedirect) {
    if (hasNewTab) {
      window.open(finalLinkToRedirect, '_blank');
    } else {
      window.location.href = finalLinkToRedirect;
    }
  }

  return finalLinkToRedirect;
};
